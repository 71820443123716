import { ServerNetwork } from '@utils/functions';
import { TERC20Contract } from '@typescript/TERC20Contract';
import { Coin } from './CCoin';
import { daiAbi } from './abi/dai/CDaiAbi';
import { daiTestnetAbi } from './abi/dai/CDaiTestnetAbi';
import { usdcAbi } from './abi/usdc/CUsdcAbi';
import { usdcTestnetAbi } from './abi/usdc/CUsdcTestnetAbi';
import { usdtAbi } from './abi/usdt/CUsdtAbi';
import { usdtTestnetAbi } from './abi/usdt/CUsdtTestnetAbi';

export enum ERC20ContractFn {
  APPROVE = 'approve',
  ALLOWANCE = 'allowance',
}

export const ERC20Contract: TERC20Contract = {
  [Coin.USDT]: {
    [ServerNetwork.BSC]: {
      address: '0x55d398326f99059fF775485246999027B3197955',
      abi: usdtAbi,
    },
    [ServerNetwork.BSC_TESTNET]: {
      address: '0x337610d27c682e347c9cd60bd4b3b107c9d34ddd',
      abi: usdtTestnetAbi,
    },
  },
  [Coin.USDC]: {
    [ServerNetwork.BSC]: {
      address: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
      abi: usdcAbi,
    },

    [ServerNetwork.BSC_TESTNET]: {
      address: '0x64544969ed7EBf5f083679233325356EbE738930',
      abi: usdcTestnetAbi,
    },
  },
  [Coin.DAI]: {
    [ServerNetwork.BSC]: {
      address: '0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3',
      abi: daiAbi,
    },

    [ServerNetwork.BSC_TESTNET]: {
      address: '0xEC5dCb5Dbf4B114C9d0F65BcCAb49EC54F6A0867',
      abi: daiTestnetAbi,
    },
  },
};
