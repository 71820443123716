export enum ListingType {
  BUY = 0,
  SELL = 1,
}

export enum MyListingRequestType {
  DELETED = 'DELETED',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  WITH_TX = 'WITH_TX',
}
