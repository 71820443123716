export enum ListingTradeStatus {
  AVAILABLE = 'Available',
  LISTED = 'Listed',
  TOKENS_RECEIVED = 'Tokens Received',
  TRANSFER_IN_PROGRESS = 'Transfer in progress',
  WAITING_FOR_BUYER = 'Waiting for buyer',
  SUSPENDED = 'Suspended Temporally',
  TOKENS_SENT = 'Tokens Sent',
  DELETED = 'Deleted',
  EXPIRED = 'Expired',
  INSUFFICIENT_FUNDS = 'Insufficient funds',
  INSUFFICIENT_TOKENS = 'Insufficient tokens',
}

export enum OfferTradeStatus {
  OFFER_SENT = 'Offer sent',
  OFFER_RECEIVED = 'Offer received',
  WAITING_FOR_OFFERER = 'Waiting for offerer',
  TRANSFER_IN_PROGRESS = 'Offer Transfer in progress',
  OFFER_SUSPENDED = 'Offer Suspended Temporally',
  OFFER_ACCEPTED = 'Offer accepted',
  INSUFFICIENT_FUNDS = 'Offer Insufficient funds',
  OFFER_REJECTED = 'Offer rejected',
  OFFER_EXPIRED = 'Offer expired',
}
