import IsLoadingCom from '@components/shared/IsLoadingCom';
import { useGetCurrentUserQuery } from '@store/api/userApi';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

interface Props {
  children: JSX.Element;
}

function AuthGuard({ children }: Props) {
  const { data: user, isLoading, isFetching } = useGetCurrentUserQuery({});
  const navigate = useNavigate();

  useEffect(() => {
    if (!user && !isLoading && !isFetching) {
      navigate('/projects');
    }
  }, [isFetching, isLoading, navigate, user]);

  if (isLoading || isFetching) {
    return <IsLoadingCom />;
  }

  return children;
}

export default AuthGuard;
