import { OtcTransferContract } from '@typescript/TOtcTransferContract';
import { ServerNetwork } from '@utils/functions';
import { testnetOtcTransferAbi } from './abi/otc/CTestnetOtcTETransferAbi';
import { otcTransferAbi } from './abi/otc/COtcTransferAbi';

// eslint-disable-next-line import/no-unused-modules
export const OTC_TRANSFER_ADMIN = '0x61a1B53C2Cb35F10eD3D5E3006EEb9481c39FC7a';
export const OTC_TRANSFER_FEE_PERCENTAGE = 10;

// eslint-disable-next-line import/no-unused-modules
export enum OTCTransferContractFn {
  HANDLE_OTC_TX = 'handleOTCTransaction',
}

// ? Lv contribution tracker contract contract (not used)
// export const contributionsTrackerContract = {
//   [ChainId.BSC_TEST_NET]: {
//     abi: contributionsTrackerAbi,
//     address: '0x982746524C13862769DA5f088fa9c768fF41EF56',
//   },
// };

// ? Lv payment split contract (not used)
export const otcTransferContract: OtcTransferContract = {
  [ServerNetwork.BSC_TESTNET]: {
    abi: testnetOtcTransferAbi,
    address: '0x12abD73af39CE27048Ba9945d860C43d601381aa',
  },
  [ServerNetwork.BSC]: {
    abi: otcTransferAbi,
    address: '0x52210E79575c1fD2FB9a05f8E573D7530F608687',
  },
};
