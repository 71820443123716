export enum ListingAction {
  BUY_WTS = 'Buy',
  SELL_WTB = 'Sell',
  MAKE_OFFER = 'Make Offer',
  BUY_WTB = 'Make Transaction',
  DELETE = 'Delete',
  EDIT = 'Edit',
  VIEW_OFFER = 'View offer',
  UPDATE_LISTING_EXPIRATION = 'Extend',
  CLAIM_EXPIRED_LISTING_TOKENS = 'Claim Tokens',
}

export enum OfferAction {
  ACCEPT_OFFER = 'Accept Offer',
  REJECT_OFFER = 'Reject Offer',
  MAKE_OFFER_TRANSACTION = 'Make Transaction',
  DELETE = 'Delete Offer',
  EDIT = 'Edit Offer',
}
