import { TResponsiveMenu, TRightSidebar } from '@typescript/TSidebar';
import { Svgs } from '@utils/svgs';

export const RightSidebarLinks: TRightSidebar[] = [
  {
    text: 'Partners & Invertors',
    hash: '#partners',
    key: 'partnersCards',
  },
  {
    text: 'Team & Advisors',
    hash: '#team',
    key: 'teamCards',
  },
  {
    text: 'Socials',
    hash: '#socials',
    key: 'socialCards',
  },
];

export const SidebarGeneralListItems = [
  {
    text: 'Dashboard',
    icon: Svgs.Dashboard,
    href: '/',
    internLink: true,
    isProjected: true,
  },
  {
    text: 'Portfolio',
    icon: Svgs.Portfolio,
    href: '/portfolio',
    internLink: true,
    isProjected: true,
  },
  {
    text: 'Projects',
    icon: Svgs.Projects,
    href: '/projects',
    internLink: true,
  },
  {
    text: 'Explore',
    icon: Svgs.Explore,
    href: '/explore',
    internLink: true,
  },
  {
    text: 'My Listings',
    icon: Svgs.MyListings,
    href: '/my-listings',
    disabled: false,
    internLink: true,
    isProjected: true,
  },
  {
    text: 'Offers',
    icon: Svgs.Offers,
    href: '/offers',
    disabled: false,
    internLink: true,
    isProjected: true,
  },
];

export const SidebarOtherListItems = [
  {
    text: 'Activity Stream',
    icon: Svgs.ActivitySteam,
    href: '/activity-stream',
    disabled: false,
    internLink: true,
    isProjected: true,
  },
  {
    text: 'Help & Support',
    icon: Svgs.HelpAndSupport,
    href: '',
    disabled: false,
    internLink: false,
  },
];

export const ResponsiveSidebarItems = [
  {
    icon: Svgs.Dashboard,
    href: '/',
    isProjected: true,
  },
  {
    icon: Svgs.Portfolio,
    href: '/portfolio',
    isProjected: true,
  },
  {
    icon: Svgs.Projects,
    href: '/projects',
  },
  {
    icon: Svgs.Explore,
    href: '/explore',
  },
  {
    icon: Svgs.ActivitySteam,
    href: '/activity-stream',
    isProjected: true,
  },
];

export const ResponsiveMenuItems: TResponsiveMenu[] = [
  {
    icon: Svgs.Account,
    href: '/account',
    disabled: false,
    name: 'Account',
    isProjected: true,
  },
  {
    icon: Svgs.HelpAndSupport,
    href: '',
    disabled: false,
    name: 'Help & Support',
  },
];
