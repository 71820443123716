import { ProjectStatus } from '@constants/CProject';

import {
  TProjectCreateDetailsInitialValues,
  TProjectHeaderInterface,
  TSocialCardObj,
} from '@typescript/TProjectDetails';
import {
  TProject,
  TProjectCreateInitialValues,
} from '@typescript/models/Project.model';

export const projectSocialInitialValues: TSocialCardObj = {
  id: '',
  imageUrl: '',
  url: '',
};

export const projectEditTeamInitialValues = {
  id: '',
  name: '',
  description: '',
  imageUrl: '',
  occupation: '',
};

export const projectEditHeaderInitialValues = (
  project: TProject,
): TProjectHeaderInterface => ({
  name: project.name || '',
  slug: project.slug || '',
  image: project.image || '',
  symbol: project.symbol || '',
});

export const projectEditValuesInitialValues = (currentProject: TProject) => ({
  networkImage: currentProject.networkImage || '',
  chain: currentProject.chain || '',
  tokenPrice: currentProject.tokenPrice?.toString() || '',
  tokenAddress: currentProject.tokenAddress?.toString() || '',
  hidden: currentProject.hidden,
  status: currentProject.status,
  maxInvestment: currentProject?.maxInvestment?.toString() || '',
  minInvestment: currentProject?.minInvestment?.toString() || '',
  fundraisingTarget: currentProject?.fundraisingTarget?.toString() || '',
});

export const projectEditOverviewInitialValues = (currentOverview: string) => ({
  overviewText: currentOverview,
});

export const createProjectInitialValues: TProjectCreateInitialValues = {
  name: '',
  image: '',
  symbol: '',
  tokenPrice: '',
  round: '',
  fundraisingTarget: '',
  feeInPercentage: '',
  feeInPercentagePrivate: '',
  maxInvestment: '',
  minInvestment: '',
  status: ProjectStatus.LIVE,
  lvWallet: '',
  vestingDetails: '',
  totalTokens: 0,
  chain: '',
  networkImage: '',
  isLegionProject: false,
};

export const createProjectDetailsInitialValues: TProjectCreateDetailsInitialValues =
  {
    overviewText: '',
    partnersCards: [],
    teamCards: [],
    socialCards: [],
    partnersCardsTouched: false,
    socialCardsTouched: false,
    teamCardsTouched: false,
    chain: '',
    networkImage: '',
  };
