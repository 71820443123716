import { OptionsObject } from 'notistack/dist/index';

const notifyHideDuration = 3000;

export const NotifySuccessSettings: OptionsObject = {
  variant: 'success',
  autoHideDuration: notifyHideDuration,
};
export const NotifyErrorSettings: OptionsObject = {
  variant: 'error',
  autoHideDuration: notifyHideDuration,
};

export const NotifyWarningSettings: OptionsObject = {
  variant: 'warning',
  autoHideDuration: notifyHideDuration,
};

export const NotifyInfoSettings: OptionsObject = {
  variant: 'info',
  autoHideDuration: notifyHideDuration,
};
