export enum ExcelProjectColumn {
  PROJECT_NAME = 0,
  LV_WALLET = 1,
  TOKEN_PRICE = 2,
  SOCIAL = 4,
  RECEIVER_WALLET = 5,
  INVESTED_AMOUNT = 6,
  INVESTED_AMOUNT_WITH_FEE = 7,
  PERCENTAGE = 8,
  TOTAL_TOKENS = 9,
  SYMBOL = 10,
  TYPE = 11,
  CHAIN = 12,
  TOKEN_ADDRESS = 13,
}

export enum ExcelDistributionColumn {
  CHAIN = 0,
  SENDER_WALLET = 1,
  RECEIVER_WALLET = 2,
  TOKENS_SENT = 3,
  AMOUNT_IN_USD = 4,
  SYMBOL = 5,
  TOKEN_ADDRESS = 6,
  CURRENCY_NAME = 7,
  BLOCK_HEIGHT = 8,
  TIMESTAMP = 9,
  HASH = 10,
}
