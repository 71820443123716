export enum TransferType {
  STABLE = 'STABLE',
  OTC_TRANSFER_CONTRACT = 'OTC_TRANSFER_CONTRACT',
}

export enum TradeModalStep {
  CLOSE = 0,
  BUY = 1,
  TX_CONFIRMATION = 2,
  TX_SENT = 3,
  TX_SUCCESS = 4,
  TX_FAILED = -1,
}
