export enum CloudFunctions {
  // ? Defaults
  CREATE_DEFAULT_LISTING = 'createDefaultListing',
  CREATE_DEFAULT_PROJECT = 'createDefaultProject',
  CREATE_DEFAULT_ALLOCATION = 'createDefaultAllocation',
  CREATE_DEFAULT_OFFER = 'createDefaultOffer',
  CREATE_DEFAULT_TOKEN_DISTRIBUTION = 'createDefaultTokenDistribution',
  CREATE_DEFAULT_FEE = 'createDefaultFee',
  CREATE_DEFAULT_USER_INFORMATIONS = 'createDefaultUserInformations',

  // ? Users
  GET_USER_BALANCE = 'getUserBalance',
  GET_USER_STABLE_BALANCE = 'getUserStableBalance',
  GET_USER_INFORMATIONS = 'getUserInformations',
  GET_USER_BY_ADDRESS = 'getUserByAddress',
  GET_CURRENT_USER = 'getCurrentUser',
  UPDATE_USER = 'updateUser',
  UPDATE_USER_INFO = 'updateUserInfo',

  // ? Listings
  CREATE_WTS = 'createWts',
  CREATE_WTB = 'createWtb',
  UPDATE_WTS = 'updateWts',
  UPDATE_WTB = 'updateWtb',
  UPDATE_LISTING_EXPIRATION = 'updateListingExpiration',
  UPDATE_LISTING_IN_PROGRESS = 'updateListingInProgress',
  SELL_WTB = 'sellWtb',
  CLAIM_EXPIRED_LISTING_TOKENS = 'claimExpiredListingTokens',
  DELETE_LISTING = 'deleteListing',
  GET_USER_LISTINGS = 'getUserListings',
  GET_ALL_LISTINGS = 'getAllListings',
  GET_ACTIVE_LISTINGS = 'getActiveListings',
  GET_LISTINGS_BY_PROJECT = 'getListingsByProject',
  GET_MY_ACTIVE_LISTINGS = 'getMyActiveListings',
  GET_MY_LISTINGS_EXPIRED = 'getMyListingsExpired',
  GET_MY_LISTINGS_DELETED = 'getMyListingsDeleted',
  GET_MY_LISTINGS_WITH_TX = 'getMyListingsWithTx',
  CHECK_LISTING_STATUS = 'checkListingStatus',
  SEARCH_LISTINGS = 'searchListings',

  // ? Offers
  CREATE_OFFER = 'createOffer',
  GET_OFFERS = 'getOffers',
  UPDATE_OFFER = 'updateOffer',
  DELETE_OFFER = 'deleteOffer',
  GET_MY_OFFERS = 'getMyOffers',
  GET_MY_RECEIVED_OFFERS = 'getMyReceivedOffers',
  GET_MY_OFFERS_WITH_TX = 'getMyOffersWithTx',
  GET_USER_OFFERS = 'getUserOffers',
  GET_MY_INACTIVE_OFFERS = 'getMyInactiveOffers',
  GET_MY_ACTIVE_OFFERS = 'getMyActiveOffers',
  UPDATE_OFFER_STATUS = 'updateOfferStatus',
  SEARCH_OFFERS = 'searchOffers',

  // ? Allocations
  GET_ALLOCATIONS = 'getAllocations',
  GET_MY_ALLOCATIONS = 'getMyAllocations',
  GET_ACTIVE_ALLOCATIONS = 'getActiveAllocations',
  CREATE_ALLOCATION_INVESTMENT = 'createAllocationInvestment',
  GET_USER_ALLOCATIONS = 'getUserAllocations',
  GET_USER_ALLOCATION_STATS = 'getUserAllocationStats',
  GET_PROJECT_ALLOCATION_STATS = 'getProjectAllocationStats',
  GET_ALLOCATION_BY_LISTING = 'getAllocationByListing',
  GET_ALLOCATION_BY_PROJECT = 'getAllocationByProject',

  // ? Projects
  GET_PROJECTS = 'getProjects',
  GET_ACTIVE_PROJECTS = 'getActiveProjects',
  CREATE_PROJECT = 'createProject',
  DELETE_ALL_PROJECTS = 'deleteAllProjects',
  GET_PROJECT_BY_ID = 'getProjectById',
  GET_PROJECT_BY_NAME = 'getProjectByName',
  GET_PROJECT_BY_SLUG = 'getProjectBySlug',
  DELETE_PROJECT = 'deleteProject',
  UPDATE_PROJECT = 'updateProject',
  UPDATE_PROJECT_DETAIL = 'updateProjectDetail',
  SEARCH_PROJECT = 'searchProject',
  RECALCULATE_EXCEL_PROJECT = 'recalculateExcelProject',
  GET_ETH_PROJECTS = 'getEthProjects',

  // ? To replace with general Edit
  UPDATE_PROJECT_HEADER = 'updateProjectHeader',
  UPDATE_PROJECT_DATA = 'updateProjectData',
  UPDATE_PROJECT_UNLOCKED_TOCKENS = 'updateUnlockedTokens',

  // ? Transactions
  CREATE_TRADE_TRANSACTION = 'createTradeTransaction',
  CREATE_TRANSACTION_INVESTMENT = 'createTransactionInvestment',
  CONFIRM_INVESTMENT_TRANSACTION = 'confirmInvestmentTransaction',
  CONFIRM_TRADE_TRANSACTION = 'confirmTradeTransaction',
  INSERT_ALL_USER_FAILED_TRANSACTIONS = 'insertAllUserFailedTransactions',
  GET_ALL_TRANSACTION_INVESTMENT_PROJECT = 'getAllTransactionsInvestmentProject',
  GET_ALL_TRANSACTION_INVESTMENT = 'getAllTransactionsInvestment',
  GET_USER_Tx = 'getUserTx',
  GET_USER_INVESTMENT_TX = 'getUserInvestmentTx',
  FIND_TX_INVESTMENT_BY_HASH = 'findTxInvestmentByHash',
  GET_MY_TRADE_TX = 'getMyTradeTx',
  GET_MY_INVESTMENT_TX = 'getMyInvestmentTx',
  GET_MY_TRADE_TX_BY_PROJECT = 'getTradeTxByProject',
  CHECK_TX_INVESTMENT = 'checkTxInvestment',

  // ? Excel
  CREATE_EXCEL_PROJECT = 'createExcelProject',
  CREATE_EXCEL_DISTRIBUTION = 'createExcelDistribution',

  // ? Jobs
  CHECK_EXPIRED_LISTINGS_JOB = 'checkExpiredListingsCloudJob',
  CHECK_USER_ALLOCATIONS_JOB = 'checkUserAllocationsCloudJob',
  SET_PROJECT_CURRENT_PRICE_JOB = 'setProjectsCurrentPriceCloudJob',
  CALCULATE_PLATFORM_VALUES_JOB = 'calculatePlatformValuesJob',
  DELETE_NOT_OTC_BSC_TRANSACTIONS = 'deleteNotOTCBscTransactionsJob',
  SYNC_OTC_TRANSACTIONS_JOB = 'syncOTCTransactionsJob',

  // ? Utils
  SEND_EMAIL = 'sendEmail',
  DELETE_ALL_DATA = 'deleteAllData',
  GET_TOKEN_DISTRIBUTIONS = 'getTokenDistributions',
  GET_WATCHED_ADDRESSES = 'getWatchedAddresses',
  GET_FEES = 'getFees',
  GET_SERVER_NETWORK = 'getServerNetwork',
  GET_SERVER_STATUS = 'getServerStatus',
  GET_TRANSFER_TYPE = 'getTransferType',
  MORALIS_REQUEST_MESSAGE = 'requestMessage',
  STRESS_TEST = 'stressTest',

  // ? ETH INVESTMENT
  CREATE_ETH_INVESTMENT = 'createEthInvestment',
  CONFIRM_ETH_INVESTMENT = 'confirmEthInvestment',
  CHECK_ETH_INVESTMENT = 'checkEthInvestment',
  GET_ETH_INVESTMENT = 'getEthInvestments',

  // ? Tiers
  GET_ELIGIBLE_INVESTMENT = 'getEligibleInvestment',

  // ? ETH ALLOCATIONS
  GET_ETH_ALLOCATIONS = 'getEthAllocations',
}
