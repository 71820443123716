import * as yup from 'yup';

export const ProjectTeamValidationSchema = yup.object().shape({
  id: yup.string().required('Required!'),
  name: yup.string().min(2, 'Too Short!').required('Required!'),
  description: yup.string().min(2, 'Too Short!').required('Required!'),
  imageUrl: yup.string().min(2, 'Too Short!').required('Required!'),
  occupation: yup.string().min(2, 'Too Short!').required('Required!'),
});

// export const ProjectRoadmapValidationSchema = yup.object().shape({
//   description: yup.string().min(2, 'Too Short!').required('Required!'),
//   value: yup.string().min(2, 'Too Short!').required('Required!'),
// });

export const ProjectSocialValidationSchema = yup.object().shape({
  id: yup.string().required('Required!'),
  imageUrl: yup.string().min(2, 'Too Short!').required('Required!'),
  url: yup.string().url().required('Required!'),
});

export const ProjectEditHeaderValidationSchema = yup.object().shape({
  name: yup.string().min(2, 'Too Short!').required('Required!'),
  image: yup.string().min(2, 'Too Short!').required('Required!'),
  symbol: yup.string().min(2, 'Too Short!').required('Required!'),
});

export const ProjectEditOverviewValidationSchema = yup.object().shape({
  overviewText: yup.string().required('Required!'),
});

export const ProjectEditValuesValidationSchema = yup.object().shape({
  tokenAddress: yup.string().nullable().min(2, 'Too Short!'),
  tokenPrice: yup
    .number()
    .positive('Only positive numbers allowed!')
    .required('Required!'),
  chain: yup.string().min(2, 'Too Short!').required('Required!'),
  networkImage: yup.string().min(2, 'Too Short!').required('Required!'),
  // symbol: yup.string().min(2, 'Too Short!').required('Required!'),
  fundraisingTarget: yup.number().positive('Only positive numbers allowed!'),
  maxInvestment: yup
    .number()
    .positive('Only positive numbers allowed!')
    .when('fundraisingTarget', (fundraisingTarget, schema) =>
      schema.max(
        fundraisingTarget,
        'Max investment must be smaller than fundraising Target',
      ),
    ),
  minInvestment: yup
    .number()
    .positive('Only positive numbers allowed!')
    .min(1, 'Minimum investment must be greater than 0')
    .when('maxInvestment', (maxInvestment, schema) =>
      schema.max(
        maxInvestment,
        'Minimum investment must be smaller than maximum investment',
      ),
    ),
});

export const CreateProjectValidationSchema = yup.object().shape({
  name: yup.string().min(2, 'Too Short!').required('Required!'),
  image: yup.string().min(2, 'Too Short!').required('Required!'),
  symbol: yup.string().min(2, 'Too Short!').required('Required!'),
  tokenPrice: yup
    .number()
    .positive('Only positive numbers allowed!')
    .required('Required!'),
  round: yup.string().min(2, 'Too Short!').required('Required!'),
  fundraisingTarget: yup
    .number()
    .positive('Only positive numbers allowed!')
    .required('Required!'),
  maxInvestment: yup
    .number()
    .positive('Only positive numbers allowed!')
    .required('Required!')
    .when('fundraisingTarget', (fundraisingTarget, schema) =>
      schema.max(
        fundraisingTarget,
        'Max investment must be smaller than fundraising Target',
      ),
    ),
  chain: yup.string().min(2, 'Too Short!').required('Required!'),
  networkImage: yup.string().min(2, 'Too Short!').required('Required!'),
  minInvestment: yup
    .number()
    .positive('Only positive numbers allowed!')
    .required('Required!')
    .min(1, 'Minimum investment must be greater than 0')
    .when('maxInvestment', (maxInvestment, schema) =>
      schema.max(
        maxInvestment,
        'Minimum investment must be smaller than maximum investment',
      ),
    ),
  feeInPercentage: yup
    .number()
    .min(0, 'Only positive numbers allowed!')
    .max(100, 'Maximum fee is 100%')
    .required('Required!'),
  feeInPercentagePrivate: yup
    .number()
    .min(0, 'Only positive numbers allowed!')
    .max(100, 'Maximum fee is 100%')
    .required('Required!'),
  lvWallet: yup
    .string()
    .min(2, 'Too Short!')
    .matches(/^0x[a-fA-F0-9]{40}$/, 'Invalid wallet address format')
    .required('Required!'),
  vestingDetails: yup.string().min(2, 'Too Short!').required('Required!'),
});

// const RoadmapValidationSchema = yup.object().shape({
//   activePoint: yup.string().required('Required.'),
//   roadmapCards: yup.array().min(1, 'At least 1 roadmap is required'),
// });

export const CreateProjectDetailsValidationSchema = yup.object().shape({
  overviewText: yup.string().min(2, 'Too Short!').required('Required!'),
  partnersCards: yup.array().min(1, 'At least 1 partner is required.'),
  teamCards: yup.array().min(1, 'At least 1 team is required.'),
  socialCards: yup.array().min(1, 'At least 1 social is required.'),
});
