export enum ActivityStreamContentType {
  BOUGHT = 0,
  SOLD = 1,
  INVESTED = 2,
  ETH_INVESTED = 3,
}

export type ActivityStreamContentTypeNames =
  keyof typeof ActivityStreamContentType;

export enum MyListingsContentType {
  ACTIVE = 0,
  INACTIVE = 1,
}

export enum OffersContentType {
  ACTIVE = 0,
  INACTIVE = 1,
}

export enum ProjectContentType {
  ALL = 0,
  NOT_LAUNCHED = 1,
  LIVE = 2,
  LIVE_PRIVATE = 3,
  DISTRIBUTING = 4,
  COMPLETED = 5,
  LEGION_PROJECT = 6,
}

export enum PortfolioContentType {
  ALLOCATIONS = 'allocations',
  ETH_ALLOCATIONS = 'eth-allocations',
}
